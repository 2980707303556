<template>
    <div class="order-list">
        <div class="table-head">
            <table>
                <colgroup>
                    <col width="400" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                    <col :width="thWidth" />
                </colgroup>
                <thead>
                    <tr>
                        <th>商品信息</th>
                        <th>发货状态</th>
                        <th>支付方式</th>
                        <th>订单金额</th>
                        <th>退款金额</th>
                        <th>收货人</th>
                        <th>申请时间</th>
                        <th>退款状态</th>
                        <th>操作</th>
                    </tr>
                    <tr style="height: 10px"></tr>
                </thead>
            </table>
        </div>
        <div class="table-body">
            <table :style="{ height: !list.length ? '100%' : '' }">
                <colgroup>
                    <col width="400" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                    <col :width="tdWidth" />
                </colgroup>
                <tbody v-if="!list.length">
                    <tr style="background: #fff">
                        <td colspan="9">
                            <No />
                        </td>
                    </tr>
                </tbody>
                <tbody v-for="(item, index) in list" :key="index">
                    <tr>
                        <td colspan="9">
                            <div class="order-head">
                                <span class="sn">退款单号：{{ item.refund_sn }}</span>
                                <span class="sn">订单编号：{{ item.order_sn }}</span>
                                <el-tag v-if="item.refund_type == 1" type="danger">退款申请</el-tag>
                                <el-tag v-if="item.refund_type == 2" type="warning">退货退款申请</el-tag>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="goods-info">
                                <div class="item" style="width: 100%">
                                    <el-image :src="item.goods_img"></el-image>
                                    <div class="goods-title">
                                        <p>{{ item.goods_title }}</p>
                                        <p v-show="item.goods_spec">规格：{{ item.goods_spec.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>{{ item.order_state == 20 ? '已付款' : item.order_state == 30 ? '已发货' : '待发货' }}</td>
                        <td>{{ item.payment_code == 10 ? '微信支付' : item.payment_code == 20 ? '支付宝支付' : item.payment_code ==
                            30 ? '华为支付' : item.payment_code == 40 ? '余额支付' : '会员卡支付' }}</td>
                        <td>￥{{ item.order_amount }}</td>
                        <td>{{ item.refund_amount }}</td>
                        <td>
                            <p style="margin-bottom: 10px">{{
                                item.order_info.order_type == 3 ? item.order_info.extend_order_extend.reciver_infos.name
                                : item.order_info.order_type == 4 ? item.order_info.extend_order_extend.delivery_infos.name
                                    : item.order_info.user_name }}</p>
                            <p>{{ item.order_info.order_type == 3 ? item.order_info.extend_order_extend.reciver_infos.phone
                                : item.order_info.order_type == 4 ?
                                    item.order_info.extend_order_extend.delivery_infos.mobile : item.order_info.user_phone }}
                            </p>
                        </td>
                        <td>{{ getDateformat(item.create_time) }}</td>
                        <td>
                            <span
                                :style="{ color: item.handleState == 1 || item.handleState == 3 ? '#f78989' : item.handleState == 2 ? '#E6A23C' : '#409EFF' }">{{
                                    item.handle_state_desc }}</span>
                        </td>
                        <td>
                            <el-button v-if="item.handleState == 1 || item.handleState == 3 || item.handleState == 9"
                                type="primary" size="small" @click="salesHandle(item.id, item.handleState)">处理售后</el-button>
                            <el-button v-else type="text" @click="salesHandle(item.id, item.handleState)">查看详情</el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Paging :total="total" :page="content.page" :pageNum="content.rows" @updatePageNum="updateData"></Paging>
    </div>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    props: {
        content: {
            type: Object,
        },
    },
    data () {
        return {
            total: 0,
            tdWidth: 0,
            thWidth: 0,
            list: [],
            showHandleSales: !1,
            currentSelectOrderInfo: {},
            handle_state: 10, //默认0处理中(未处理) 10拒绝(驳回) 20同意 30成功(已完成) 50取消(用户主动撤销) 51取消(用户主动收货)
        };
    },
    components: {
        Paging: Paging,
    },
    computed: {},
    watch: {},
    mounted () {
        this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
        this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
    },
    created () {
        let utils = this.$store.state.utils;
        if (utils.is_record) {
            this.content.page = utils.page;
            this.content.rows = utils.rows;
            utils.is_record = !1;
        }
        this.getList();
    },
    methods: {
        getList () {
            let content = this.content;
            let data = {
                page: content.page,
                rows: content.rows,
                create_time: content.create_time,
            };
            if (content.payment_code != 'whole') {
                data.payment_code = content.payment_code;
            }
            if (content.refund_state > 0) {
                data.refund_state = content.refund_state;
            }
            if (content.keywords) {
                data.keywords_type = content.keywords_type;
                data.keywords = content.keywords;
            }
            this.$axios.post(this.$api.order.salesList, data).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        let handleState = ''; //1：等待退款处理 2：同意申请，等待买家退货  3：买家已发货，等待收货 4:已收货，确认退款 5:退款成功 6:退款关闭 7同意退款，仅退款 8:拒绝(驳回)
                        let handle_state = list[i].handle_state;
                        if (handle_state == 0) {
                            handleState = 1;
                        }
                        if (handle_state == 20 && list[i].refund_type == 2 && !list[i].tracking_no && !list[i].tracking_time) {
                            handleState = 2;
                        }
                        if (handle_state == 20 && list[i].refund_type == 2 && list[i].tracking_no != null && list[i].tracking_time > 0 && list[i].receive == 1) {
                            handleState = 3;
                        }
                        if (handle_state == 20 && list[i].refund_type == 2 && list[i].tracking_no != null && list[i].tracking_time > 0 && list[i].receive == 2) {
                            handleState = 4;
                        }
                        if (handle_state == 30) {
                            handleState = 5;
                        }
                        if (list[i].is_close == 1 && (handle_state == 50 || handle_state == 51)) {
                            handleState = 6;
                        }
                        if (handle_state == 20 && list[i].refund_type == 1) {
                            handleState = 7;
                        }
                        if (handle_state == 10) {
                            handleState = 8;
                        }
                        if (list[i].refund_type == 1) {
                            if (list[i].is_close == 0 && handle_state >= 20 && list[i].handle_time > 0) handleState = 9;
                        } else {
                            if (list[i].is_close == 0 && handle_state >= 20 && list[i].handle_time > 0 && !list[i].tracking_no && !list[i].tracking_time && list[i].receive == 2) handleState = 9;
                        }
                        list[i].handle_state_desc =
                            handleState == 1
                                ? '待处理'
                                : handleState == 2
                                    ? '待买家发货'
                                    : handleState == 3
                                        ? '待商家收货'
                                        : handleState == 4
                                            ? '确认收货并退款'
                                            : handleState == 5
                                                ? '退货退款成功'
                                                : handleState == 6
                                                    ? '退款关闭'
                                                    : handleState == 7
                                                        ? '退款成功'
                                                        : handleState == 8
                                                            ? '驳回申请'
                                                            : '代补款';
                        list[i].handleState = handleState;
                    }
                    this.total = res.result.total_number;
                    this.list = list;
                    this.$nextTick(() => {
                        console.log(document.getElementsByClassName('table-body')[0].scrollHeight);
                        console.log(document.getElementsByClassName('table-body')[0].clientHeight);
                        if (document.getElementsByClassName('table-body')[0].scrollHeight > document.getElementsByClassName('table-body')[0].clientHeight) {
                            this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
                            this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 413) / 8;
                        } else {
                            this.thWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
                            this.tdWidth = (document.getElementsByClassName('order-list')[0].clientWidth - 400) / 8;
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        updateData (val, status) {
            if (status == 0) {
                this.content.rows = val;
            } else {
                this.content.page = val;
            }
            this.getList();
        },
        salesHandle (id, status) {
            this.$router.push({
                path: '/goods/salesHandle',
                query: {
                    id: id,
                    status: status,
                    page: this.content.page,
                    rows: this.content.rows,
                },
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
    },
};
</script>

<style lang="less" scoped>
.table-head {
    table {
        font-size: 14px;

        thead tr th {
            padding: 15px;
            text-align: center;
        }

        thead tr {
            background: #f8f9fa;

            th:first-child {
                width: 280px;
            }
        }
    }
}

.table-body {
    table {
        font-size: 14px;

        tbody {
            border: 1px solid #ebeef5;
            box-sizing: border-box;
        }

        tbody tr:first-child {
            border-bottom: 1px solid #ebeef5;

            background: #f8f9fa;

            td {
                padding: 5px 20px;
            }
        }

        tbody tr:last-child {
            td {
                text-align: center;
            }
        }

        .goods-info {
            width: 300px;
            flex: none;
            padding-left: 20px;

            .item {
                height: 70px;
                display: flex;
                align-items: center;

                .el-image {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    border-radius: 5px;
                }

                .goods-title {
                    text-align: left;
                    flex: 1;

                    p:nth-child(1) {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .order-head {
            display: flex;
            align-items: center;

            .sn {
                margin-right: 50px;
            }

            .el-tag {
                margin-right: 10px;
            }

            div {
                display: flex;
                align-items: center;
            }
        }

        .goods-price p {
            flex: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 70px;
            justify-content: center;

            span:nth-child(1) {
                margin-bottom: 5px;
            }
        }

        .freight_fee {
            p:nth-child(1) {
                margin-bottom: 10px;
            }

            i {
                font-size: 15px;
            }
        }

        .consignee p {
            &:nth-child(1) {
                margin-bottom: 10px;
            }
        }
    }
}

/deep/ .el-timeline-item__timestamp {
    border-bottom: 1px solid #e8eef1;
    padding-bottom: 10px;
}

.message {
    display: flex;

    /deep/ .el-form-item__content {
        flex: 1;
    }
}
</style>
